<template>
    <div class="box">
        <van-nav-bar :title="$route.meta.title" left-text="" left-arrow @click-left="onClickNavLeft" :fixed="true"
            :placeholder="true" z-index="999" style="background:#05B252;">
            <template #left>
                <img src="@/assets/img/fanhuibai.png" alt="" class="navFanhui imgFit">
            </template>
        </van-nav-bar>
        <div class="bj"></div>

        <div class="outer">

            <div class="userinfo">
                <div class="userphone">{{ phone }}</div>
                <div @click="tlogin" class="tlogin">退出登录</div>
            </div>
            <div class="tishi_box">
                <div class="Look_out">
                    <img src="@/assets/img/tihsi.png" alt="">
                    <div class="tishi_title">提示：</div>
                </div>

                <div class="tishi" v-html="info.user_jiang_info"></div>
            </div>
            <div class="Redpacket">
                <div class="Redpacket_left">
                    <div class="Redpacket_img_box">
                        <img class="Redpacket_img" src="@/assets/img/hongbao.png" alt="">
                        <div class="mine_hongbao">我的红包</div>
                    </div>
                    <div class="Price_box">
                        <div class="Price">{{ mineInfo.money ? mineInfo.money : 0 }}</div>
                        <div class="yuan">元</div>
                    </div>
                </div>
                <div class="Redpacket_right" @click="tixian" style="margin-bottom: -30px;">
                    提现
                </div>

                <div class="Refresh_box" @click="refresh">
                    <img :class="isRefresh == true ? 'animated rotateOut' : ''" src="@/assets/img/shuaxin.png" alt="">
                </div>
            </div>


            <div class="Redpacket">
                <div class="Redpacket_left">
                    <div class="Redpacket_img_box">
                        <img class="Redpacket_img_yhk" src="@/assets/img/yhk.png" alt="">
                        <div class="mine_hongbao">银行卡</div>
                    </div>
                    <div v-if="mineInfo" :class="[mineInfo.bank.bank_no ? 'Price_box_yhkactive' : 'Price_box_yhk']">
                        {{ mineInfo.bank.bank_no ? mineInfo.bank.bank_no : '请添加提现银行卡' }}
                    </div>
                    <div v-else class="Price_box_yhk">
                        请添加提现银行卡
                    </div>
                </div>
                <div class="Redpacket_right" @click="toyinhang" v-if="mineInfo">
                    {{ mineInfo.bank.bank_no ? '修改' : '添加' }}
                </div>
                <div class="Redpacket_right" @click="toyinhang" v-else>
                    添加
                </div>
            </div>

            <!-- 红包  答题  提现-记录-->
            <div class="tabbar_list">
                <div @click="tabbarck(index)" :class="[tabIndex == index ? 'tabactive' : 'tab']"
                    v-for="(item, index) in tablist" :key="index">{{
                        item.name }}
                </div>
            </div>

            <!-- 列表 -->
            <template v-if="kong == false">
                <div class="Litt_box" v-for="(item, index) in jiluList" :key="index">
                    <div class="Litt_box_one">
                        <div class="Litt_box_one_left">
                            <div v-if="tabIndex == 0" class="Litt_box_one_left_title">{{ item.memo }}</div>
                            <div v-if="tabIndex == 0" class="Litt_box_one_left_timer">{{ item.createtime }}</div>


                            <div v-if="tabIndex == 1" class="Litt_box_one_left_title">{{ item.answer_status ==
                                1 ? '答题成功' : '答题失败' }}
                            </div>
                            <div v-if="tabIndex == 1" class="Litt_box_one_left_timer">{{ item.create_time }}</div>

                            <div v-if="tabIndex == 2" class="Litt_box_one_left_title">提现（{{ item.status ==
                                0 ? '待抽奖' : item.status
                                    == 1 ? '已中奖' : '未中奖' }}）</div>
                            <div v-if="tabIndex == 2" class="Litt_box_one_left_timer">{{ item.createtime }}</div>
                        </div>
                        <!-- 红包记录显示 -->
                        <div :class="[item.type == 1 ? 'prickjia' : 'pricejian']" v-if="tabIndex == 0">
                            {{ item.type == 1 ? '+' + item.money : '-' + item.money }}元
                        </div>
                        <!-- 答题记录显示 -->
                        <div class="Litt_box_one_right" v-if="tabIndex == 1">
                            <div class="Litt_box_one_right_box_one">
                                <img class="dui" src="@/assets/img/dui.png" alt="">
                                <div class="duinNum">{{ item.correct_count }}</div>
                            </div>
                            <div class="Litt_box_one_right_box_tow">
                                <img class="dui" src="@/assets/img/cha.png" alt="">
                                <div class="duinNum">{{ item.count - item.correct_count }}</div>
                            </div>
                        </div>
                        <!-- 提现记录显示-->
                        <div :class="[item.status == 0 || item.status == 1 ? 'prickjia' : 'pricejian']"
                            v-if="tabIndex == 2">
                            -{{ item.money }}元
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="ismone == true" class="isMone">到底了~</div>

            <!-- 空状态 -->
            <div class="kong" v-if="kong == true">
                <div class="kong_box">
                    <img src="@/assets/img/kong.png" alt="">
                    <div>暂无记录~</div>
                </div>
            </div>
        </div>

        <van-popup @click-overlay="back" v-model="show" round position="bottom" :style="{ height: '580px' }">
            <div class="popup_box" v-if="mineInfo">
                <div class="tixian_title">提现</div>
                <div class="tiixan_price">
                    <div></div>
                    <input @input="ckprick" v-model="prickyuan" type="number">
                    <div>元</div>
                </div>
                <div class="yinhang_title">
                    <div>提现账号</div>
                    <div>{{ mineInfo.bank.main_bank_name ? mineInfo.bank.main_bank_name : '暂未添加银行卡' }}</div>
                </div>
                <div class="yhkh" @click="cktx">
                    <input v-if="mineInfo.bank.bank_no" disabled v-model="mineInfo.bank.bank_no" type="number"
                        placeholder="请添加银行卡号">
                    <div v-else class="ckkh">请添加银行卡号</div>
                </div>
                <div class="Withdrawal_password">提现密码</div>
                <div class="ipt_list">
                    <input maxlength="1" v-model="num_one1" @input="moveNext(1)" ref="input1" type="number"
                        @keydown="moveBack(1, $event)">
                    <input maxlength="1" v-model="num_one2" @input="moveNext(2)" ref="input2" type="number"
                        @keydown="moveBack(2, $event)">
                    <input maxlength="1" v-model="num_one3" @input="moveNext(3)" ref="input3" type="number"
                        @keydown="moveBack(3, $event)">
                    <input maxlength="1" v-model="num_one4" @input="moveNext(4)" ref="input4" type="number"
                        @keydown="moveBack(4, $event)">
                    <input maxlength="1" v-model="num_one5" @input="moveNext(5)" ref="input5" type="number"
                        @keydown="moveBack(5, $event)">
                    <input maxlength="1" v-model="num_one6" @input="moveNext(6)" ref="input6" type="number"
                        @keydown="moveBack(6, $event)">
                </div>
                <div class="tishimima">默认提现密码为手机号后六位</div>
                <div class="zuidi">
                    <div class="zuidi_title">最低提现金额</div>
                    <div class="qian">¥{{ info.withdrawal_limit }}</div>
                </div>
                <div class="form" @click="queren">确认</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：我的
 * @author: 刘义博
 * @version  V1.0
 * @datecreate: 2024-04-18 8:52
 */
export default {
    inject: ["reload"], // 刷新页面
    data() {
        return {
            prickyuan: '',
            ismone: false,
            kong: true,
            page: 1,
            show: false,
            kh: '',
            yhname: '',
            tabIndex: 0,
            num_one1: '',
            num_one2: '',
            num_one3: '',
            num_one4: '',
            num_one5: '',
            num_one6: '',
            tablist: [
                {
                    id: 1,
                    name: '红包记录'
                },
                {
                    id: 2,
                    name: '答题记录'
                },
                {
                    id: 3,
                    name: '提现记录'
                }
            ],
            mineInfo: '',//我的页面详情
            jiluList: [],//记录列表

            islogin: 0,
            isShow: false,
            istabshow: false,
            phone: '',
            isRefresh: false,
            repeat: false,
            info: '',
        };
    },
    computed: {
        disposefn() {
            return this.$store.state.dispose;
        },
    },
    watch: {
        disposefn: {
            handler: function (val, oldval) {
                this.info = val
            },
            deep: true
        },
    },
    mounted() {
        window.addEventListener("scroll", this.listenBottomOut);//监听下拉刷新
        if (localStorage.getItem('token')) {
            this.islogin = 1
            this.getmine()
            this.getliebiao()
            this.phone = localStorage.getItem('phone')
            this.info = this.$store.state.dispose
        } else {
            this.islogin = 0
            this.$toast({
                message: '请先参与活动后查看',
                duration: 1500,
            });
            setTimeout(() => {
                this.$router.push({ path: "/" });
            }, 1500)
        }
    },

    methods: {
        /**
          * 刷新
          * 刘义博
          * 2024-4-18
       */
        refresh() {
            this.isRefresh = true
            this.getmine()
            setTimeout(() => {
                this.isRefresh = false
            }, 1000)
        },
        /**
          * 退出登录
          * 刘义博
          * 2024-4-16
       */
        tlogin() {
            localStorage.clear()
            this.$router.push({ path: "/" });
        },
        /**
          * 提现弹框点击请添加银行卡
          * 刘义博
          * 2024-4-16
       */
        cktx() {
            if (!this.mineInfo.bank.user_bank_id) {
                this.$router.push({ path: "/Bankcard" });
            }
        },
        /**
           * 提现金额只能保留两位小数
           * 刘义博
           * 2024-4-16
        */
        ckprick() {
            if (isNaN(this.prickyuan)) {
                // 如果不是数字，则将输入框的值设置为空
                this.prickyuan = '';
            } else {
                // 如果是数字，则限制小数部分最多只能有两位
                const parts = this.prickyuan.toString().split('.');
                // 如果小数部分存在，并且小数部分的长度大于两位，则进行截取
                if (parts.length === 2 && parts[1].length > 2) {
                    this.prickyuan = parseFloat(this.prickyuan).toFixed(2);
                }
            }
        },
        /**
           * 关闭弹框
           * 刘义博
           * 2024-4-16
        */
        back() {
            this.prickyuan = ''
            this.num_one1 = ''
            this.num_one2 = ''
            this.num_one3 = ''
            this.num_one4 = ''
            this.num_one5 = ''
            this.num_one6 = ''
        },
        /**
           * 确认提现
           * 刘义博
           * 2024-4-16
        */
        queren() {
            if (this.repeat == true) {
                return
            }
            let passwords = this.num_one1 + this.num_one2 + this.num_one3 + this.num_one4 + this.num_one5 + this.num_one6
            if (!this.mineInfo.bank.user_bank_id) {
                this.$toast({
                    message: '请先添加银行卡！',
                    duration: 1500,
                });
                return
            }
            if (!this.prickyuan) {
                this.$toast({
                    message: '请输入提现金额！',
                    duration: 1500,
                });
                return
            }
            if (this.prickyuan < 5) {
                this.$toast({
                    message: `提现金额最低不能低于${this.info.withdrawal_limit}元!`,
                    duration: 1500,
                });
                return
            }
            if (passwords.length < 6) {
                this.$toast({
                    message: '请输入提现密码！',
                    duration: 1500,
                });
                return
            }
            this.repeat = true
            this.$request({
                url: "/Index/withdrawal",
                data: {
                    user_bank_id: this.mineInfo.bank.user_bank_id,//银行卡id
                    pay_password: passwords,//密码
                    money: this.prickyuan,//金额
                },
            }).then((res) => {
                setTimeout(() => {
                    this.repeat = false
                }, 2000)
                if (res.code == 1) {
                    this.page = 1
                    this.ismone = false
                    this.kong = false
                    this.jiluList = []
                    this.show = false
                    this.prickyuan = ''
                    this.num_one1 = ''
                    this.num_one2 = ''
                    this.num_one3 = ''
                    this.num_one4 = ''
                    this.num_one5 = ''
                    this.num_one6 = ''
                    this.getmine()
                    this.getliebiao()
                    this.$toast({
                        message: res.msg,
                        duration: 1500,
                    });
                }
            }).catch((error) => {
                setTimeout(() => {
                    this.repeat = false
                }, 2000)
                this.$toast({
                    message: error.msg,
                    duration: 1500,
                });
            });
        },
        /**
           * 去添加银行卡
           * 刘义博
           * 2024-4-16
        */
        toyinhang() {
            if (this.islogin == 0) {
                this.$toast({
                    message: '请先在首页点击参与活动查看',
                    duration: 1500,
                });
                return
            }
            this.$router.push({ path: "/Bankcard" });
        },
        /**
          * tab切换
          * 刘义博
          * 2024-4-16
       */
        getliebiao() {
            if (this.islogin == 0) {
                this.$toast({
                    message: '请先在首页点击参与活动查看',
                    duration: 1500,
                });
                return
            }
            let index = this.tabIndex
            let url;
            if (index == 0) {
                url = '/Index/money_log'
            } else if (index == 1) {
                url = '/Index/answer_log'
            } else if (index == 2) {
                url = '/Index/withdrawal_log'
            }
            this.$request({
                url: url,
                data: {
                    page: this.page,
                },
            }).then((res) => {
                if (res.code == 1) {
                    if (res.data.length > 0) {
                        this.page = ++this.page
                        this.jiluList = this.jiluList.concat(res.data)
                        this.kong = false
                        this.ismone = false
                    } else {
                        if (this.page > 1) {
                            this.ismone = true
                        } else {
                            this.kong = true
                        }
                    }
                }
            }).catch((error) => {
                if (error.code == -3) {
                    this.$toast({
                        message: '登录过期请重新参与活动填写手机号!',
                        duration: 1500,
                    });
                    localStorage.removeItem('token')
                    setTimeout(() => {
                        this.$router.push({ path: "/" });
                    }, 1500)
                    return
                }
                this.$toast({
                    message: error.msg,
                    duration: 1500,
                });
            });
        },
        /**
           * 我的
           * 刘义博
           * 2024-4-16
        */
        async getmine() {
            this.$request({
                url: "/Index/my",
                data: {
                    mobile: this.phone,
                },
            }).then((res) => {
                if (res.code == 1) {
                    this.mineInfo = res.data
                }
            }).catch((error) => {

                if (error.code == -3) {
                    this.$toast({
                        message: '登录过期请重新参与活动填写手机号!',
                        duration: 1500,
                    });
                    localStorage.removeItem('token')
                    setTimeout(() => {
                        this.$router.push({ path: "/" });
                    }, 1500)
                    return
                }
                this.$toast({
                    message: error.msg,
                    duration: 1500,
                });
            });
        },
        /**
          * 提现按钮
          * 刘义博
          * 2024-4-16
       */
        tixian() {
            if (this.islogin == 0) {
                this.$toast({
                    message: '请先在首页点击参与活动查看',
                    duration: 1500,
                });
                return
            }
            this.show = true
        },
        /**
           * 提现密码
           * 刘义博
           * 2024-4-16
        */
        moveNext(index) {
            const input = this.$refs[`input${index}`];
            input.value = input.value.replace(/\D/g, '');//只能输入数字
            if (input.value.length > 1) {
                input.value = input.value.slice(0, 1); // 截取第一位数
                this[`num_one${index}`] = input.value; // 更新对应的数据属性
            }
            const nextInput = this.$refs[`input${index + 1}`];
            if (nextInput && input.value.length === 1) {
                nextInput.focus();
            }
        },
        /**
           * 提现密码删除
           * 刘义博
           * 2024-4-16
        */
        moveBack(index, event) {
            if (event.keyCode === 8 || event.keyCode == 'Backspace') {
                if (this[`num_one${index}`].length < 1) {
                    const nextInput = this.$refs[`input${index - 1}`];
                    if (nextInput) {
                        nextInput.focus();
                    }
                }
            }
        },
        /**
           * tab点击
           * 刘义博
           * 2024-4-16
        */
        tabbarck(index) {
            if (this.istabshow == true) {
                return
            }
            if (index == this.tabIndex) {
                return
            }
            if (index != 1) {
                this.getmine()
                console.log('刷新了我的');
            }
            this.jiluList = [],//记录列表
                this.isShow = true
            this.istabshow = true
            this.tabIndex = index
            this.page = 1
            this.ismone = false
            this.kong = false
            this.getliebiao()
            setTimeout(() => {
                this.istabshow = false
            }, 200)
            setTimeout(() => {
                this.isShow = false
            }, 1000)


        },
        /**
           * 返回上一页
           * 刘义博
           * 2024-4-16
        */
        onClickNavLeft() {
            this.$router.push({ path: "/" });
            // this.$router.go(-1)
        },
        /**
          * 触底操作
          * 刘义博
          * 2024-4-16
       */
        listenBottomOut() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const clientHeight = document.documentElement.clientHeight;
            const scrollHeight = document.documentElement.scrollHeight;
            if (scrollTop + clientHeight >= scrollHeight) {
                console.log("触底了~");
                if (this.isShow == true) {
                    return
                }
                this.getliebiao()
            }
        },
    },
    destroyed() {
        // 离开页面取消监听
        console.log('取消监听');
        window.removeEventListener("scroll", this.listenBottomOut, false);
    },
};
</script>
<style lang='scss' scoped>
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.tishi_box {
    background: #fff;
    padding: 27px 36px;
    border-radius: 24px;
    box-sizing: border-box;
    margin-bottom: 30px;

    .Look_out {
        display: flex;
        align-items: center;

        img {
            width: 33px;
            height: 33px;
            margin-right: 10px;
        }

        .tishi_title {
            font-size: 29px;
            color: #D22F2A;
            font-weight: bold;
        }
    }

    .tishi {
        color: red;
        font-size: 26px;
        letter-spacing: .05em;
    }

}



@keyframes rotateOut {
    0% {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        opacity: 1
    }
}

.rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut
}

body {
    background-color: red;
}

:deep(.van-nav-bar__title) {
    color: #ffffff !important;
    margin: 0 auto 0 40px !important;
    font-size: 19px !important;
}

:deep(.van-nav-bar) {
    background: #05B252 !important;
    border: none !important;
    position: fixed !important;
}

:deep(.van-hairline--bottom::after) {
    border: none !important;
}

:deep(.van-nav-bar__content) {
    height: 56px !important;
}

.box {
    background: #F4F4F4 !important;
}

* {
    box-sizing: border-box;
}

.bj {
    width: 100%;
    height: 200px;
    background: #05B252 !important;
    margin-top: -1px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.outer {
    position: relative;
    background: #F4F4F4;
    border-radius: 60px 60px 0px 0px;
    padding: 40px 36px;
    min-height: 300px;

    .userinfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px 0;

        .userphone {
            font-size: 34px;
            color: #222222;
            font-weight: bold;
        }

        .tlogin {
            width: 170px;
            height: 64px;
            background: #FFFFFF;
            border-radius: 32px;
            font-weight: bold;
            font-size: 26px;
            color: #222222;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.Redpacket {
    height: 200px;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 47px 36px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;

    .Refresh_box {
        background: linear-gradient(0deg, #05B252 0%, #05B252 100%);
        border-radius: 0px 12px 0px 12px;
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 11px;

        img {
            width: 31px;
            height: 31px;
        }
    }

    .Redpacket_left {
        display: flex;
        flex-direction: column;

        .Redpacket_img_box {
            display: flex;
            align-items: center;

            .Redpacket_img {
                width: 32px;
                height: 32px;
                margin-right: 9px;
            }

            .Redpacket_img_yhk {
                width: 32px;
                height: 22px;
                margin-right: 9px;
            }

            .mine_hongbao {
                font-weight: bold;
                font-size: 28px;
                color: #444444;
            }
        }

        .Price_box_yhk {
            font-weight: 400;
            font-size: 30px;
            color: #999999;
            margin-top: 41px;
        }

        .Price_box_yhkactive {
            font-weight: 400;
            font-size: 30px;
            color: #222222;
            margin-top: 41px;
        }

        .Price_box {
            margin-top: 35px;
            display: flex;
            align-items: center;

            .Price {
                font-weight: bold;
                font-size: 52px;
                color: #D22F2A;
                margin-right: 13px;
            }

            .yuan {
                font-weight: bold;
                font-size: 34px;
                color: #D22F2A;
                margin-bottom: -2px;
            }

        }


    }

    .Redpacket_right {
        width: 136px;
        height: 68px;
        background: #05B252;
        border-radius: 34px;
        font-weight: bold;
        font-size: 30px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.tabbar_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 35px 0;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tab {
        width: 170px;
        height: 64px;
        background: #FFFFFF;
        border-radius: 32px;
        font-weight: 400;
        font-size: 26px;
        color: #222222;
    }

    .tabactive {
        width: 170px;
        height: 64px;
        background: #05B252;
        border-radius: 32px;
        font-weight: bold;
        font-size: 26px;
        color: #FFFFFF;
    }
}

.Litt_box_one {
    height: 130px;
    background: #FFFFFF;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 36px;
    box-sizing: border-box;
    margin-bottom: 30px;

    .Litt_box_one_left {
        .Litt_box_one_left_title {
            font-weight: 400;
            font-size: 28px;
            color: #222222;
        }

        .Litt_box_one_left_timer {
            font-weight: 400;
            font-size: 26px;
            color: #999999;
            margin-top: 25px;
        }
    }

    .prickjia {
        font-weight: bold;
        font-size: 30px;
        color: #D22F2A;
    }

    .pricejian {
        font-weight: bold;
        font-size: 30px;
        color: #999999;
    }

    .pricejian1 {
        font-weight: bold;
        font-size: 30px;
        color: #222222;
    }

    .Litt_box_one_right {
        display: flex;
        align-items: center;

        .Litt_box_one_right_box_one {
            display: flex;
            align-items: center;
            margin-right: 38px;

            .dui {
                width: 24px;
                height: 24px;
                margin-right: 9px;
            }

            .duinNum {
                font-weight: bold;
                font-size: 28px;
                color: #05B252;
            }
        }

        .Litt_box_one_right_box_tow {
            display: flex;
            align-items: center;

            .dui {
                width: 24px;
                height: 24px;
                margin-right: 9px;
            }

            .duinNum {
                font-weight: bold;
                font-size: 28px;
                color: #D5465E;
            }
        }
    }

}

.popup_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 94px 80px 94px;
    // height: 70vh;
    // overflow-y: auto;

    .tixian_title {
        font-weight: bold;
        font-size: 34px;
        color: #222222;
    }

    .tiixan_price {
        width: 530px;
        height: 140px;
        border-radius: 16px;
        border: 4px solid #E2E2E2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 47px 0 0;
        margin-top: 60px;

        input {
            width: 100%;
            border: 0 !important;
            height: 90%;
            text-align: center;
            font-size: 42px;
            color: #222222;
            padding-left: 90px;
            box-sizing: border-box;
        }

        div {
            font-size: 44px;
            color: #999999;
            font-weight: bold;
        }
    }

    .yinhang_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 59px;
        margin-bottom: 20px;

        div {
            font-weight: bold;
            font-size: 28px;
            color: #222222;
        }
    }

    .yhkh {
        width: 100%;
        height: 96px;
        border-radius: 16px;
        border: 4px solid #E2E2E2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 43px;

        input {
            width: 100%;
            font-weight: 400;
            font-size: 32px;
            color: #222222;
            border: 0 !important;
            height: 90%;
            text-align: center;
            background: #fff;
        }

        .ckkh {
            background: #fff;
            color: #E2E2E2;
            width: 100%;
            font-weight: 400;
            font-size: 32px;
            height: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 16px;
            height: 22px;
        }
    }

    .Withdrawal_password {
        width: 100%;
        font-weight: bold;
        font-size: 28px;
        color: #222222;
        margin: 59px 0 20px 0;
        text-align: left;
    }

    .ipt_list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 80px;
            height: 96px;
            border-radius: 16px;
            border: 4px solid #E2E2E2;
            text-align: center;
            margin-right: 10px;
            font-size: 40px;
        }

        input:last-of-type {
            margin-right: 0 !important;
        }
    }

    .tishimima {
        font-weight: 400;
        font-size: 26px;
        color: #888888;
        margin-top: 19px;
        width: 100%;
        text-align: left;
    }

    .zuidi {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 85px;

        .zuidi_title {
            font-weight: 400;
            font-size: 26px;
            color: #888888;
        }

        .qian {
            font-weight: 400;
            font-size: 26px;
            color: #222222;
        }
    }

    .form {
        width: 280px;
        height: 96px;
        background: #05B252;
        border-radius: 48px;
        font-weight: bold;
        font-size: 34px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
    }

}

.kong {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 140px;

    .kong_box {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 100px;
            height: 100px;

        }

        div {
            font-size: 25px;
            color: #C0C0C0;
            margin-top: 10px;
        }
    }

}

.isMone {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    color: #C0C0C0;
    font-size: 28px;
}
</style>